import React, { useCallback, useEffect } from 'react';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'usehooks-ts';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { FieldValues, useForm } from 'react-hook-form';
import adminImg from './images/admin.svg';
import memberImg from './images/member.svg';
import pen from '../../assets/images/pen.svg';
import styles from './users.module.scss';
import {
  createUserAction, deleteUserAction, getUserAction, updateUserAction,
} from '../../actions/users';
import { useAppDispatch } from '../../reducers';
import {
  ErrorField, InputEmail, InputText,
} from '../../lib/HooksFormFields';
import InputFile from '../../components/InputFile/InputFile';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect/Select';

export const rolesOptions = [
  { value: 'Admin', label: 'Administrateur' },
  { value: 'Member', label: 'Membre' },
];

const User = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const {
    user,
  } = useSelector((d: any) => d.usersReducer);
  const salesPointsReducer = useSelector((d: any) => d.salesPointsReducer);
  const {
    lists,
  } = salesPointsReducer;

  const navigate = useNavigate();
  const params = useParams();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getUser = useCallback(() => {
    if (params?.id) {
      getUserAction(dispatch, params?.id);
    } else {
      reset({
        role: 'Member',
      });
    }
  }, [dispatch, params?.id]);

  async function onSubmit(values: FieldValues) {
    if (!user?._id) {
      return createUserAction(dispatch, values);
    }
    return updateUserAction(dispatch, user._id, values);
  }

  async function deleteUser() {
    await deleteUserAction(dispatch, user._id);
    return navigate('/team');
  }

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (user?._id) {
      reset(user);
    }
  }, [user]);

  return (
    <div className={styles.container}>
      {width < 1024
        ? <div className={styles.mobile}>
          <p>Page non disponible sur mobile</p>
        </div>
        : <div className={styles.desktop}>
          <div className={styles.header}>
            <Link to="/team">
              <IoIosArrowRoundBack />
              RETOUR AUX MEMBRES
            </Link>
            <div className={styles.actions}>
            {user?.role === 'Member'
              && <button
                  type='button'
                  className={styles.delete}
                  onClick={() => deleteUser()}
                >
                  <RiDeleteBinLine />
                  Supprimer ce membre
                </button>
              }
              <button
                type='button'
                className={styles.btn}
                onClick={handleSubmit(onSubmit)}
              >
                Sauvegarder
              </button>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.col}>

                <InputFile
                  submit={(picture: string) => handleSubmit(() => onSubmit({ picture })) }
                  id={'profile.picture'}
                >
                  {user?.picture?.path
                    ? <>
                      <div className={styles.picture}>
                        <div className={styles.icon}>
                          <img src={pen} alt="edit"/>
                        </div>
                        <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`}
                          className={styles.img}
                        />
                    </div>
                  </> : <div className={`${styles.picture} ${user?.role === 'Admin' ? styles.admin : ''}`}>
                    <div className={styles.icon}>
                      <img src={pen} alt="edit"/>
                    </div>
                    <p>{user?.profile?.firstName[0]}{user?.profile?.lastName[0]}</p>
                  </div>}
                </InputFile>

            </div>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <InputText
                  name="profile.firstName"
                  control={control}
                  rules={{ required: 'le prénom est requis' }}
                  className="primary"
                  label="Prénom"
                />
                {(errors.profile && (errors.profile as any)?.firstName?.message)
                && <div className={styles.error}>
                  <ErrorField
                  message={errors.profile && (errors.profile as any)?.firstName?.message}
                />
                </div>}
              </div>
              <div className={styles['container-field']}>
                <InputText
                  name="profile.lastName"
                  control={control}
                  rules={{ required: 'le nom est requis' }}
                  className="primary"
                  label="Nom"
                />
                  {(errors.profile && (errors.profile as any)?.lastName?.message)
                  && <div className={styles.error}>
                  <ErrorField
                  message={errors.profile && (errors.profile as any)?.lastName?.message}
                />
                </div>}
              </div>
              <div className={styles['container-field']}>
                <InputEmail
                  name="email"
                  control={control}
                  required
                  className="primary"
                  label="Adresse mail"
                />
                  {(typeof errors.email?.message === 'string')
                  && <div className={styles.error}>
                  <ErrorField
                  message={errors.email.message || ''}
                />
                </div>}
              </div>
              <div className={styles['container-field']}>
                <InputText
                  name="phone"
                  control={control}
                  rules={{ }}
                  className="primary"
                  label="Numéro de téléphone"
                />
                  {(typeof errors.phone?.message === 'string')
                  && <div className={styles.error}>
                  <ErrorField
                  message={errors.phone.message || ''}
                />
                </div>}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles['container-field']}>
                <StyledSelect
                  name="role"
                  control={control}
                  rules={{ }}
                  className="primary"
                  options={rolesOptions}
                  label="Rôle"
                />
                  {(typeof errors.role?.message === 'string')
                  && <div className={styles.error}>
                  <ErrorField
                  message={errors.role.message || ''}
                />
                </div>}
              </div>
              <div className={styles['container-field']}>
                <StyledSelect
                  name="agentCode"
                  control={control}
                  rules={{ }}
                  className="primary"
                  options={lists?.agentsOptions || []}
                  label="Code agent"
                />
              </div>
            </div>
           </div>
           <div className={styles.illu}>
            {user?.role && <img src={user?.role === 'Admin' ? adminImg : memberImg } alt="user"/>}
          </div>
        </div>}
    </div>);
};

export default User;
