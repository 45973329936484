import {
  VISIT_LOADING,
  VISIT_ERROR,
  GET_USER_VISITS,
  UPDATE_VISIT,
  CREATE_VISIT,
  DELETE_VISIT,
  DELETE_MANY_VISIT,
  GET_USER_ARCHIVED_VISITS,
  SET_VISIT_APPOINTMENT,
} from '../actions/types';
import { ActionReducer } from '../types';
import { IVisit } from '../types/visits';

interface VisitsState {
  visits: IVisit[],
  archivedVisits: IVisit[] | null,
  isLoading: string[],
  appointment: IVisit | null,
  error: string | null,
}

const DEFAULT_STATE: VisitsState = {
  visits: [],
  archivedVisits: [],
  isLoading: [],
  appointment: null,
  error: null,
};

// eslint-disable-next-line default-param-last
const visitsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const visits : any = [...state.visits];
  const visitIndex = visits.findIndex((v: any) => v._id === action.payload?._id);
  switch (action.type) {
    case GET_USER_ARCHIVED_VISITS:
      updatedState = {
        ...state,
        archivedVisits: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case GET_USER_VISITS:
      updatedState = {
        ...state,
        visits: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case CREATE_VISIT:
      updatedState = {
        ...state,
        visits: [action.payload, ...visits],
      };
      break;
    case UPDATE_VISIT:
      if (visitIndex !== -1) {
        visits[visitIndex] = action.payload;
      }
      updatedState = {
        ...state,
        visits,
      };
      break;
    case DELETE_VISIT:
      updatedState = {
        ...state,
        visits: visits.filter((v: any) => v?._id !== action.payload),
      };
      break;
    case DELETE_MANY_VISIT:
      updatedState = {
        ...state,
        visits: visits.filter((v: any) => v?.isVisited !== action.payload),
      };
      break;
    case VISIT_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SET_VISIT_APPOINTMENT:
      updatedState = { ...state, appointment: action.payload };
      break;
    case VISIT_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default visitsReducer;
