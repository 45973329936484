import { Dispatch } from 'redux';
import {
  deleteData, getData, postData, putData,
} from '.';
import {
  CREATE_USER,
  DELETE_USER,
  GET_USER,
  POST_USERS_FILTERED,
  SET_TOAST,
  UPDATE_USER,
  USERS_ERROR,
  USERS_LOADING,
} from './types';

export const postUsersFilteredAction = async (dispatch: Dispatch, data: any) => {
  dispatch({
    type: USERS_LOADING,
    payload: POST_USERS_FILTERED,
  });
  const url = '/users/filtered';
  const response = await postData(USERS_ERROR, url, dispatch, data, true);
  if (response?.data) {
    dispatch({
      type: POST_USERS_FILTERED,
      payload: {
        ...response.data,
        page: data.page,
      },
    });
  }
  return response;
};

export const getUserAction = async (dispatch: Dispatch, id: string) => {
  const url = `/users/${id}`;
  const response = await getData(USERS_ERROR, url, dispatch, true);
  if (response?.data?.user) {
    dispatch({
      type: GET_USER,
      payload: response.data?.user,
    });
  }
};

export const createUserAction = async (dispatch: Dispatch, data: any) => {
  const url = '/users/';
  const response = await postData(USERS_ERROR, url, dispatch, data, true);
  if (response?.data?.user) {
    dispatch({
      type: CREATE_USER,
      payload: response.data.user,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'L\'utilisateur a été créé',
      },
    });
  }
};

export const updateUserAction = async (dispatch: Dispatch, id: string, data: any) => {
  const url = `/users/${id}`;
  const response = await putData(USERS_ERROR, url, dispatch, data, true);

  if (response.data.user) {
    dispatch({
      type: UPDATE_USER,
      payload: response.data.user,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'L\'utilisateur a été modifié',
      },
    });
  }
  return response;
};

export const deleteUserAction = async (dispatch: Dispatch, id: string) => {
  const url = `/users/${id}`;
  const response = await deleteData(USERS_ERROR, url, dispatch, true);
  if (response) {
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
  }
  return response;
};
