import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styles from './Pagination.module.scss';

type Props = {
  pageNumber: number;
  totalPages: number;
  onChangePage: (pageNumber: number) => void;
};

function Pagination({ pageNumber, totalPages, onChangePage }: Props) {
  function handlePageClick(newPageNumber: number) {
    onChangePage(newPageNumber);
  }

  function handlePrevClick() {
    if (pageNumber >= 1) {
      const newPageNumber = pageNumber - 1;
      onChangePage(newPageNumber);
    }
  }

  function handleNextClick() {
    if (pageNumber < totalPages) {
      const newPageNumber = pageNumber + 1;
      onChangePage(newPageNumber);
    }
  }

  return (
    <div className={styles.pagination}>
      <div
        className={`${styles.pageNumber} ${pageNumber === 0 ? styles.disabled : ''}`}
        onClick={handlePrevClick}
      >
        <FaChevronLeft />
      </div>
      {Array.from({ length: totalPages }, (_, i) => i).filter((i) => {
        if (i === 1) return i;
        if (i === 2) return i;
        if (i === pageNumber) return i;
        if (i === pageNumber + 1) return i;
        if (i === pageNumber + 2) return i;
        if (i === totalPages - 2) return i;
        if (i === totalPages - 1) return i;
        return null;
      }).map((number) => (
        <div
          key={number}
          className={`${styles.pageNumber} ${
            number - 1 === pageNumber ? styles.active : ''
          }`}
          onClick={() => handlePageClick(number - 1)}
        >
          {(number === totalPages - 2 && pageNumber < number) || (number === 2 && pageNumber > number) ? '...' : number}
        </div>
      ))}
      <div
        className={`${styles.pageNumber} ${
          pageNumber === totalPages - 1 ? styles.disabled : ''
        }`}
        onClick={() => handleNextClick()}
      >
        <FaChevronRight />
      </div>
    </div>
  );
}

export default Pagination;
