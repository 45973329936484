/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';

import arrow from './images/arrow.svg';
import styles from './DropDown.module.scss';

export default function DropDown({
  label,
  values,
  handleChange,
  options,
  allOptions = 'Tous',
  isSearch = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const getHeight = useCallback(() => {
    let height = 190;
    if (!isSearch) {
      height = 36.5 * options.length;
    }
    return height;
  }, [isSearch]);

  const filteredOptions = options
    ?.filter((opt) => {
      if (!search || !isSearch) {
        return opt;
      }
      if (opt?.label?.toLowerCase()?.includes(search.toLowerCase())) {
        return opt;
      }
      return null;
    });

  function onChange(value) {
    let selected = [...values];
    if (values.find((v) => v === value)) {
      selected = values.filter((v) => v !== value);
    } else {
      selected.push(value);
    }
    handleChange(selected);
  }

  return (
    <div className={styles.container} onMouseLeave={isOpen ? () => setIsOpen(false) : null}>
      <div className={styles.header}>
        <p onClick={() => setIsOpen(!isOpen)}>
          {label}
          {values?.length === 0
          && (
          <>
            {' : '}
            <span>
              {allOptions}
            </span>
          </>
          )}
          {values?.length > 0
          && (
          <>
            {' : '}
            <span>
              {values?.length} {label === 'Types' && label.toLowerCase()}
            </span>
          </>
          )}
        </p>
        {options
        && (
        <button
          type="button"
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={arrow} alt="toggle" />
        </button>
        )}
      </div>
      {options
        && (
        <div
          className={styles.dropdown}
          style={{ height: isOpen ? getHeight() : 0 }}
        >
          {isSearch
            && <input
              type="text"
              value={search}
              placeholder="Votre recherche"
              onChange={(e) => setSearch(e.target.value)}
            />
          }
          <div
            className={`${styles.options} ${isSearch ? styles.isSearch : ''}`}
          >
            {options?.filter((o) => values.find((v) => v === o.value))
              ?.map((opt, i) => (
                <button
                  type="button"
                  key={opt.label + i}
                  className={styles.active}
                  onClick={() => onChange(opt.value)}
                >
                  {' '}
                  <p>{opt.label}</p>
                </button>
              ))}
            {filteredOptions?.filter((o) => !values.find((v) => v === o.value))
              ?.map((opt, i) => (
                <button
                  type="button"
                  key={opt.label + i}
                  onClick={() => onChange(opt.value)}
                >
                  {' '}
                  <p>{opt.label}</p>
                </button>
              ))}
          </div>
        </div>
        )}
    </div>
  );
}
