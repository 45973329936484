import React from 'react';
import ProfileLink from '../ProfileLink';
import logo from './images/logo.svg';
import styles from './header.module.scss';

const Header = ({ name }: { name: string }) => (
  <div className={styles.container}>
    <div className={styles.logo}>
      <img src={logo} alt='logo'/>
      <p>{name}</p>
    </div>
    <ProfileLink />
  </div>
);

export default Header;
