import { Dispatch } from 'redux';
import { getData, postData } from '.';
import { RequestFunction } from './salesPoints';
import {
  GET_SALSES_POINT_APPOINTMENTS, SALES_POINTS_ERROR, SALES_POINTS_LOADING,
} from './types';

export const postAppointmentAction: RequestFunction = async (dispatch, data) => {
  const url = '/appointments';
  const response = await postData(SALES_POINTS_ERROR, url, dispatch, data, true);
  return response;
};

export const getAppointmentsAction = async (dispatch: Dispatch, siret: string) => {
  const url = `/appointments/${siret}`;
  dispatch({
    type: SALES_POINTS_LOADING,
    payload: GET_SALSES_POINT_APPOINTMENTS,
  });
  const response = await getData(SALES_POINTS_ERROR, url, dispatch, true);
  console.log(response);
  if (response?.data?.appointments) {
    dispatch({
      type: GET_SALSES_POINT_APPOINTMENTS,
      payload: response.data?.appointments,
    });
  }
  return response;
};
