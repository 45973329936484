import React from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { OptionType } from '../../../types';
import { ISalesPoint } from '../../../types/salesPoints';
import Informations from '../../../components/SalesPoint/Infos';
import styles from './mobile.module.scss';
import SalePointPanel from '../../../components/SalePointPanel';
import { tabsIcons } from '../../../contants';
import Appointments from '../../../components/SalesPoint/Appointments';

const SalesPointMobile = ({
  salesPoint,
  goBack,
}: {
  salesPoint: ISalesPoint,
  goBack: () => void
}) => {
  const { lists } = useSelector((d: any) => d.salesPointsReducer);
  const formatedDate = new Intl.DateTimeFormat('fr', {
    dateStyle: 'short',
  }).format(new Date(salesPoint.updatedAt));
  const formatedHour = new Intl.DateTimeFormat('fr', {
    timeStyle: 'short',
  }).format(new Date(salesPoint.updatedAt));
  const status = lists?.customersStatus?.find(
    (c: OptionType) => c.value === salesPoint.customerStatus,
  );

  return (<div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.col}>
        <button
          type="button"
          onClick={() => goBack()}
          className={styles.back}>
          <GoArrowLeft />
          <p>Point de vente</p>
        </button>
        <p className={styles.date}>Dernière modif<br/>{formatedDate} à {formatedHour}</p>
        {status
          && <p className={styles.status}
            style={{
              border: `1px solid ${status.color}`,
            }}
          >
          {status?.value === 'active-customer'
            && <span>
            Point de vente
            {' '}
            <span style={{ color: status.color }}>
              actif
            </span>
          </span>
          }
          {status?.value === 'customer'
            && <span>
              Point de vente
              {' '}
              <span style={{ color: status.color }}>
                inactif
              </span>
            </span>
          }
          {status?.value === 'lead'
            && <span>
              Prospect
              {' '}
              <span style={{ color: status.color }}>
                inactif
              </span>
            </span>
          }
          {status?.value === 'active-lead'
            && <span>
              Prospect
              {' '}
              <span style={{ color: status.color }}>
                actif
              </span>
            </span>
          }
          </p>
        }
      </div>
      <div className={styles.col}>
        {salesPoint?.picture?.path
          && <img src={`${process.env.REACT_APP_API_URL}/public/${salesPoint.picture.path}`}
                        className={styles.img}
                      />
        }
      </div>
    </div>
    <div>
      <h1>{salesPoint?.company?.name || salesPoint?.name}</h1>
    </div>
    <SalePointPanel
      label="Informations"
      icon={tabsIcons.information}
      isOpen={true}
    >
      <Informations />
    </SalePointPanel>
    <SalePointPanel
      label="Rendez-vous"
      icon={tabsIcons.meetings}
      isOpen={false}
    >
      <Appointments />
    </SalePointPanel>
  </div>
  );
};

export default SalesPointMobile;
