import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../actions/auth';
import img from '../../assets/images/login.svg';
import logo from '../../assets/images/logo-full.svg';
import EmailResetPwdForm from '../../components/form/AuthForm/EmailResetPwdForm';

import styles from './forgot-password.module.scss';

const ForgotPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/pages/center');
  }, [authReducer.user]);

  function submitForgot(data: FieldValues) {
    forgotPassword(dispatch, data).then((res: any) => {
      if (res?.status === 200) {
        setMessage(
          'Un email permettant de changer votre mot de passe vous a été envoyé.',
        );
      }
    });
  }

  return (
    <div className={styles['forgot-password']}>
      <div className={styles.picture}>
        <img src={img} alt="logo" />
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <h3>Nouveau mot de passe</h3>
          <EmailResetPwdForm
            submit={submitForgot}
            signinLink="/"
            succeedMessage={message}
            errorMessage={authReducer.error}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
