/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'usehooks-ts';
import { FaPlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { POST_USERS_FILTERED, SET_USERS_FILTERS } from '../../actions/types';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../reducers';

import Pagination from '../../components/Pagination/Pagination';
import Search from '../../components/Search';
import DropDown from '../../components/DropDown';
import { getUserVisitsAction } from '../../actions/visits';
import { postUsersFilteredAction } from '../../actions/users';
import { IUser } from '../../types/user';

import styles from './users.module.scss';
import UserRow from '../../components/UserRow';

const Users = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const {
    filters, users, count, isLoading, lists,
  } = useSelector((d: any) => d.usersReducer);

  const totalPages = count && filters.limit ? Math.ceil(count / filters.limit) : 0;

  const postUsersFiltered = useCallback(() => {
    postUsersFilteredAction(dispatch, filters);
  }, [dispatch, filters]);

  const getUserVisits = useCallback(() => {
    getUserVisitsAction(dispatch);
  }, [dispatch]);

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  useEffect(() => {
    getUserVisits();
    postUsersFiltered();
  }, []);

  useEffect(() => {
    if (width) {
      postUsersFiltered();
    }
  }, [filters]);

  return (
    <div className={styles.container}>
      {width < 1024
        ? <div className={styles.mobile}>
            <p>Page non disponible sur mobile</p>
          </div>
        : <div className={styles.desktop}>
          <div className={styles.header}>
            <div className={styles.title}>
              <h2>Équipe</h2>
              <Link to="/team/create">
                <FaPlus />
                Nouveau
              </Link>
            </div>
            <div className={styles['filters-container']}>
              <div className={styles.filters}>
                {lists?.roles
                && <>
                  <div className={styles.filter}>
                    <DropDown
                      label="Rôles"
                      values={filters.roles}
                      handleChange={(values: string[]) => handleChangeFilters({
                        roles: values,
                      })}
                      options={lists.roles}
                    />
                  </div>
                </>
                }
              </div>
              <div className={styles.search}>
                <Search
                  handleChangeFilters={handleChangeFilters}
                  search={filters.search}
                />
              </div>
            </div>
          </div>
            <div className={styles.content}>
                <div className={styles['list-container']}>
                  {isLoading.find((s: string) => s === POST_USERS_FILTERED)
                    ? <div className={styles.loader}>
                      <Loader />
                    </div>
                    : <>
                      <div className={styles.list}>
                        <div className={`${styles.row} ${styles.labels}`}>
                          <div className={styles.col}>
                          </div>
                          <div className={styles.col}>
                            <p>Prénom</p>
                          </div>
                          <div className={styles.col}>
                            <p>Nom</p>
                          </div>
                          <div className={styles.col}>
                            <p>Adresse mail</p>
                          </div>
                          <div className={styles.col}>
                            <p>Téléphone</p>
                          </div>
                          <div className={styles.col}>
                            <p>Rôle</p>
                          </div>
                        </div>
                      {users.map((u: IUser) => <UserRow
                        key={u._id}
                        user={u}
                        styles={styles}
                      />)}
                    </div>
                    </>
                  }
                  {totalPages > 1
                    && <div className={styles.pagination}>
                      <Pagination
                        pageNumber={filters.page}
                        totalPages={totalPages}
                        onChangePage={(page) => handleChangeFilters({ page })}
                      />
                    </div>
                  }
                </div>

            </div>
        </div>}
    </div>);
};

export default Users;
