import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IoIosArrowRoundBack, IoMdClose } from 'react-icons/io';
import filtersIcon from './images/filters.svg';
import search from './images/search.svg';
import { SET_SALSES_POINTS_FILTERS } from '../../actions/types';
import { useAppDispatch } from '../../reducers';
import styles from './MobileFilters.module.scss';
import { OptionType } from '../../types';

const MobileFilters = () => {
  const {
    filters, lists,
  } = useSelector((d: any) => d.salesPointsReducer);
  const dispatch = useAppDispatch();
  const [inputSearch, setInputSearch] = useState(filters.search);
  const [isSaving, setIsSaving] = useState(false);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [searchCity, setSearchCity] = useState('');

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_SALSES_POINTS_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  function handleChangeFilterItem(value: string, type: string) {
    if (!filters[type]) {
      return null;
    }
    setSearchCity('');
    let listUpdated = [...filters[type]];
    if (listUpdated.find((d) => d === value)) {
      listUpdated = listUpdated.filter((d) => d !== value);
    } else {
      listUpdated.push(value);
    }
    return handleChangeFilters({
      [type]: listUpdated,
      page: 0,
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setInputSearch(newText);
    setIsSaving(true);
  };

  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      if (isSaving) {
        // Perform your save action here (e.g., an API call)
        handleChangeFilters({
          page: 0,
          search: inputSearch,
        });
        setIsSaving(false);
      }
    }, 1000);

    return () => clearTimeout(saveTimeout);
  }, [inputSearch, isSaving]);

  return <div className={styles.container}>
    <div className={styles.search}>
      <img src={search} alt="search"/>
      <input
        value={inputSearch}
        placeholder="Rechercher..."
        onChange={(e) => handleChange(e)}
      />
    </div>
    <button
      type="button"
      className={`${styles.toggle}`}
      onClick={() => setPanelIsOpen(true)}
    >
      <img src={filtersIcon} alt="search"/>
    </button>
    <div className={`${styles.panel} ${panelIsOpen ? styles.open : ''}`}>
      <button
        type="button"
        className={`${styles.close}`}
        onClick={() => setPanelIsOpen(false)}
      >
        <IoIosArrowRoundBack />
        <p>Filtres</p>
      </button>
      <div className={styles.filters}>
        <label>Types</label>
        <div className={`${styles.list}`}>
          <button
            type="button"
            className={filters?.customerStatus?.length === 0 ? styles.active : ''}
            onClick={() => handleChangeFilters({
              customerStatus: [],
            })}
          >
            Tous
          </button>
          {lists?.customersStatus?.map((s: OptionType) => <button
              type="button"
              key={s.value}
              className={filters?.customerStatus?.find((f : string) => f === s.value) ? styles.active : ''}
              onClick={() => handleChangeFilterItem(s.value, 'customerStatus')}
            >
              {s.label}
            </button>)}
        </div>
        <label>Départements</label>
        <div className={`${styles.list}`}>
          <button
            type="button"
            className={filters?.departements?.length === 0 ? styles.active : ''}
            onClick={() => handleChangeFilters({
              departements: [],
            })}
          >
            Tous
          </button>
          {lists?.departements?.map((s: OptionType) => <button
              type="button"
              key={s.value}
              className={filters?.departements?.find((f : string) => f === s.value) ? styles.active : ''}
              onClick={() => handleChangeFilterItem(s.value, 'departements')}
            >
              {s.label}
            </button>)}
        </div>
        <label>Villes</label>
        <div className={styles.search}>
          <input
            value={searchCity}
            onChange={(e) => setSearchCity(e.target.value)}
            placeholder="Rechercher une ville"
          />
          <button
            type="button"
            onClick={() => setSearchCity('')}>
            <IoMdClose />
          </button>
        </div>
        <div className={`${styles.list}`}>
          <button
            type="button"
            className={filters?.cities?.length === 0 ? styles.active : ''}
            onClick={() => handleChangeFilters({
              cities: [],
            })}
          >
            Toutes
          </button>
          {lists?.cities?.filter(
            (c: OptionType) => filters?.cities?.find((f : string) => f === c.value),
          )?.map((s: OptionType) => <button
              type="button"
              key={s.value}
              className={styles.active}
              onClick={() => handleChangeFilterItem(s.value, 'cities')}
            >
              {s.label}
            </button>)}
            {searchCity && lists?.cities?.filter(
              (c: OptionType) => c.value.toUpperCase()
                .includes(searchCity.toUpperCase())
                && !filters?.cities?.find((f : string) => f === c.value),
            ).filter(
              (c: OptionType, i: number) => i < 6,
            )?.map((s: OptionType) => <button
              type="button"
              key={s.value}
              onClick={() => handleChangeFilterItem(s.value, 'cities')}
            >
              {s.label}
            </button>)}
        </div>
      </div>
      <div className={styles['btn-container']}>
        <button
          type="button"
          onClick={() => setPanelIsOpen(false)}
        >
          Appliquer les filtres
        </button>
      </div>
    </div>
  </div>;
};

export default MobileFilters;
