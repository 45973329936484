import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FieldValues, useForm } from 'react-hook-form';
import { MdLogout } from 'react-icons/md';
import pen from '../../assets/images/pen.svg';
import styles from './profile.module.scss';
import { useAppDispatch } from '../../reducers';
import {
  ErrorField, InputEmail, InputText,
} from '../../lib/HooksFormFields';
import InputFile from '../../components/InputFile/InputFile';
import Header from '../../components/Header';
import { logout, updateProfile } from '../../actions/auth';

const Profile = () => {
  const dispatch = useAppDispatch();
  const {
    user,
  } = useSelector((d: any) => d.authReducer);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  async function onSubmit(values: FieldValues) {
    return updateProfile(dispatch, values);
  }

  async function handleLogout() {
    await logout(dispatch);
  }

  useEffect(() => {
    if (user?._id) {
      reset(user);
    }
  }, [user]);

  return (
    <div className={styles.container}>
      <Header name="Mon compte" />
      <div className={styles.content}>
        <div className={styles.col}>
          <h2>Mon compte</h2>
          {user?._id && <>
            <InputFile
              submit={(picture: string) => updateProfile(dispatch, { picture }) }
              id={'profile.picture'}
            >
              {user?.picture?.path
                ? <>
                  <div className={styles.picture}>
                    <div className={styles.icon}>
                      <img src={pen} alt="edit"/>
                    </div>
                    <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`}
                      className={styles.img}
                    />
                </div>
              </> : <div className={`${styles.picture} ${user?.role === 'Admin' ? styles.admin : ''}`}>
                <div className={styles.icon}>
                  <img src={pen} alt="edit"/>
                </div>
                <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
              </div>}
            </InputFile>
          </>}

          <div className={styles['container-field']}>
            <InputText
              name="profile.firstName"
              control={control}
              rules={{ required: 'le prénom est requis' }}
              className="primary"
              label="Prénom"
            />
            {(errors.profile && (errors.profile as any)?.firstName?.message)
            && <div className={styles.error}>
              <ErrorField
              message={errors.profile && (errors.profile as any)?.firstName?.message}
            />
            </div>}
          </div>
          <div className={styles['container-field']}>
            <InputText
              name="profile.lastName"
              control={control}
              rules={{ required: 'le nom est requis' }}
              className="primary"
              label="Nom"
            />
              {(errors.profile && (errors.profile as any)?.lastName?.message)
              && <div className={styles.error}>
              <ErrorField
              message={errors.profile && (errors.profile as any)?.lastName?.message}
            />
            </div>}
          </div>
          <div className={styles['container-field']}>
            <InputEmail
              name="email"
              control={control}
              required
              className="primary"
              label="Adresse mail"
            />
              {(typeof errors.email?.message === 'string')
              && <div className={styles.error}>
              <ErrorField
              message={errors.email.message || ''}
            />
            </div>}
          </div>
          <div className={styles['container-field']}>
            <InputText
              name="phone"
              control={control}
              rules={{ }}
              className="primary"
              label="Numéro de téléphone"
            />
              {(typeof errors.phone?.message === 'string')
              && <div className={styles.error}>
              <ErrorField
              message={errors.phone.message || ''}
            />
            </div>}
          </div>
            <button
            className={styles.logout}
            type="button"
            onClick={() => handleLogout()}
          >
            <span>Se déconnecter</span>
            <MdLogout />
          </button>
          <button
          className={styles.submit}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          Sauvegarder
        </button>
        </div>
        <div className={styles.col}>

        </div>
    </div>
  </div>);
};

export default Profile;
