// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';

export const GET_SALSES_POINTS_LISTS = 'GET_SALSES_POINTS_LISTS';
export const POST_SALSES_POINTS_FILTERED = 'POST_SALSES_POINTS_FILTERED';
export const SET_SALSES_POINTS_FILTERS = 'SET_SALSES_POINTS_FILTERS';
export const GET_SALSES_POINT_MAP = 'GET_SALSES_POINT_MAP';
export const SET_SALSES_POINTS_VIEW = 'SET_SALSES_POINTS_VIEW';
export const GET_SALSES_POINT_LOCATION = 'GET_SALSES_POINT_LOCATION';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SALES_POINTS_ERROR = 'SALES_POINTS_ERROR';
export const SALES_POINTS_LOADING = 'SALES_POINTS_LOADING';
export const UPDATE_SALES_POINT = 'UPDATE_SALES_POINT';
export const GET_SALSES_POINT = 'GET_SALSES_POINT';
export const GET_SALSES_POINT_APPOINTMENTS = 'GET_SALSES_POINT_APPOINTMENTS';
export const SET_BACK_URL = 'SET_BACK_URL';

export const GET_USERS_LISTS = 'GET_USERS_LISTS';
export const POST_USERS_FILTERED = 'POST_USERS_FILTERED';
export const SET_USERS_FILTERS = 'SET_USERS_FILTERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER = 'GET_USER';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_LOADING = 'USERS_LOADING';

export const VISIT_LOADING = 'VISIT_LOADING';
export const VISIT_ERROR = 'VISIT_ERROR';
export const GET_USER_VISITS = 'GET_USER_VISITS';
export const SET_VISIT_APPOINTMENT = 'SET_VISIT_APPOINTMENT';
export const GET_USER_ARCHIVED_VISITS = 'GET_USER_ARCHIVED_VISITS';
export const CREATE_VISIT = 'CREATE_VISIT';
export const UPDATE_VISIT = 'UPDATE_VISIT';
export const DELETE_VISIT = 'DELETE_VISIT';
export const DELETE_MANY_VISIT = 'DELETE_MANY_VISIT';
