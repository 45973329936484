import React, { useState, ReactNode } from 'react';
import axios from 'axios';
import styles from './InputFile.module.scss';

export default function InputFile({
  submit,
  id,
  className = '',
  btnText = 'Télécharger un fichier',
  accept = '',
  capture = null,
  children = null,
}:{
  submit: any
  id: string,
  btnText?: string,
  className?: string
  accept?: string,
  capture?: any,
  children?: ReactNode,
}): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
  function handleChange(selectorFiles: FileList | any) {
    if (selectorFiles && selectorFiles.length > 0) {
      const files = selectorFiles;
      setErrorMessage(null);
      const formData = new FormData();
      formData.append('file', files[0]);
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        const config: any = {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'multipart/form-data',
          },
        };
        axios.post(`${process.env.REACT_APP_API_URL}/files`, formData, config)
          .then((response) => {
            resolve(response.data);
            submit(response.data.file._id);
          })
          .catch((error) => {
            reject(error);
            setErrorMessage(error);
          });
      });
    }
    return null;
  }
  return (
    <div className={`${styles.container} ${className ? styles[className] : ''}`}>
      <input type="file" accept={accept} capture={capture} id={id} multiple onChange={(e : React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.files)} />
      {/* {!className
      && (
      <label htmlFor={id} className={`${styles.btn} ${styles.secondary}`}>
        {btnText}
        {errorMessage && <p>{errorMessage}</p>}
      </label>
      )} */}
      {children
      && (
      <label htmlFor={id}>
        {children}
      </label>
      )}
      {className === 'cover'
      && (
      <label htmlFor={id} className={`${className ? styles[className] : ''}`}>

        <h4>{btnText}</h4>
        <span>Fichier image ou vidéo</span>
        {errorMessage && <p>{errorMessage}</p>}
      </label>
      )}
    </div>
  );
}
