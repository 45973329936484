import React from 'react';
import { MdOutlineStarPurple500 } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { OptionType } from '../../types';
import { IUser } from '../../types/user';

const UserRow = ({
  user,
  styles,
}:{
  user: IUser,
  styles: any,
}) => {
  const { lists } = useSelector((d: any) => d.usersReducer);
  const status = lists?.roles?.find((c: OptionType) => c.value === user.role);

  return (
    <Link to={`/team/${user._id}`} className={styles.row}>
      <div className={styles.col}>
        {user.picture?.path
          ? <div className={`${styles.picture} ${styles[user.role]}`}>
            <img
              src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`}
              alt={user.profile.lastName}
            />
          </div>
          : <div className={`${styles.picture} ${styles[user.role]}`}>
          {user.profile.firstName[0]}{user.profile.lastName[0]}
        </div>
        }
      </div>
      <div className={styles.col}>
        <p className={styles.name}>{user.profile.firstName}</p>
      </div>
      <div className={styles.col}>
        <p className={styles.name}>{user.profile.lastName}</p>
      </div>
      <div className={styles.col}>
        <p>{user.email}</p>
      </div>
      <div className={styles.col}>
        <p>{user.phone}</p>
      </div>
      <div className={`${styles.col}`}>
        <div className={`${styles.role} ${styles[user.role]}`}>
          {user.role === 'Admin' && <MdOutlineStarPurple500 />}
          {status?.label}
        </div>
      </div>
    </Link>
  );
};

export default UserRow;
