import React, { useState } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'usehooks-ts';
import Appointments from '../../../components/SalesPoint/Appointments';
import Informations from '../../../components/SalesPoint/Infos';
import { salesPointsTabs } from '../../../contants';
import { OptionType } from '../../../types';
import { ISalesPoint } from '../../../types/salesPoints';
import styles from './desktop.module.scss';

const SalesPointDesktop = ({
  salesPoint,
  goBack,
}: {
  salesPoint: ISalesPoint,
  goBack: () => void
}) => {
  const { width } = useWindowSize();
  const { lists, backUrl } = useSelector((d: any) => d.salesPointsReducer);
  const { appointments } = useSelector((d: any) => d.salesPointsReducer);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const formatedDate = new Intl.DateTimeFormat('fr', {
    dateStyle: 'short',
  }).format(new Date(salesPoint.updatedAt));
  const formatedHour = new Intl.DateTimeFormat('fr', {
    timeStyle: 'short',
  }).format(new Date(salesPoint.updatedAt));
  const status = lists?.customersStatus?.find(
    (c: OptionType) => c.value === salesPoint.customerStatus,
  );
  return (<div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.col}>
        <button
          type="button"
          onClick={() => goBack()}
          className={styles.back}>
          <GoArrowLeft />
          {backUrl?.includes('/visits') || backUrl?.includes('/archived') ? <p>RETOUR A MA TOURNée</p> : <p>RETOUR AUX POINTS DE VENTES</p>}
        </button>
        {status
          && <div className={styles.name}>
            <p className={styles.status}
              style={{
                border: `1px solid ${status.color}`,
              }}
            >
            {status?.value === 'active-customer'
              && <span>
              Point de vente
              {' '}
              <span style={{ color: status.color }}>
                actif
              </span>
            </span>
            }
            {status?.value === 'customer'
              && <span>
                Point de vente
                {' '}
                <span style={{ color: status.color }}>
                  inactif
                </span>
              </span>
            }
            {status?.value === 'lead'
              && <span>
                Prospect
                {' '}
                <span style={{ color: status.color }}>
                  inactif
                </span>
              </span>
            }
            {status?.value === 'active-lead'
              && <span>
                Prospect
                {' '}
                <span style={{ color: status.color }}>
                  actif
                </span>
              </span>
            }
          </p>
          <h1>{salesPoint?.company?.name || salesPoint?.name}</h1>
        </div>
        }
      </div>
      <div className={styles.col}>
        <p className={styles.date}>Dernière modification le {formatedDate} à {formatedHour}</p>
      </div>
    </div>
    <div className={styles['tabs-links']}>
      {salesPointsTabs.map((tab, i) => (
        <button
          key={tab.label}
          type='button'
          className={`${styles['tab-link']} ${tabIndex === i ? styles.active : ''} ${appointments.length === 0 ? styles.hidden : ''}`}
          onClick={() => setTabIndex(i)}
        >
          <div className={styles.icon}>
            <img src={tab.icon} alt={tab.label} />
          </div>
          <p>{tab.label}</p>
        </button>
      ))}
    </div>
    <div className={styles.tabs}>
      <div
        className={styles['tabs-container']}
        style={{
          width: width * 2,
          transform: `translateX(-${width * tabIndex}px)`,
        }}
      >
        <div className={styles.tab}>
          {tabIndex === 0
          && <div className={styles['tab-content']}>
            <Informations />
          </div>
          }
        </div>
        <div className={styles.tab}>
          {tabIndex === 1
            && <div className={styles['tab-content']}>
              <Appointments />
            </div>
            }
        </div>
      </div>
    </div>

  </div>
  );
};

export default SalesPointDesktop;
