import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authReducer from './authReducer';
import salesPointsReducer from './salesPointsReducer';
import visitsReducer from './visitsReducer';
import usersReducer from './usersReducer';

const rootReducer = combineReducers({
  authReducer,
  salesPointsReducer,
  visitsReducer,
  usersReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
