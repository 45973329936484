/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback, useEffect, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'usehooks-ts';
import { MdMap, MdFormatListBulleted } from 'react-icons/md';
import { BiSolidGridAlt } from 'react-icons/bi';
import { useSearchParams } from 'react-router-dom';
import { postSalesPointsFilteredAction } from '../../actions/salesPoints';
import {
  SET_BACK_URL, SET_SALSES_POINTS_FILTERS, SET_SALSES_POINTS_VIEW, SET_TOAST, VISIT_ERROR,
} from '../../actions/types';
import Card from '../../components/Card';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import MobileFilters from '../../components/MobileFilters';
import { useAppDispatch } from '../../reducers';
import { SalesPointItem } from '../../types/salesPoints';
import styles from './base.module.scss';
import Pagination from '../../components/Pagination/Pagination';
import Search from '../../components/Search';
import DropDown from '../../components/DropDown';
import SalesPointRow from '../../components/SalesPointRow';
import { getUserVisitsAction } from '../../actions/visits';
import CustomMap from '../../components/Map';

const Base = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const { error } = useSelector((d: any) => d.visitsReducer);
  const salesPointsReducer = useSelector((d: any) => d.salesPointsReducer);
  const {
    filters, salesPoints, view, count, isLoading, lists,
  } = salesPointsReducer;
  const divRef = useRef(null);

  const totalPages = count && filters.limit ? Math.ceil(count / filters.limit) : 0;

  const postSalesPointsFiltered = useCallback(() => {
    postSalesPointsFilteredAction(dispatch, filters);
  }, [dispatch, filters]);

  const getUserVisits = useCallback(() => {
    getUserVisitsAction(dispatch);
  }, [dispatch]);

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_SALSES_POINTS_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  function handleChangeView(string : string) {
    searchParams.set('view', string);
    setSearchParams(searchParams);
  }

  const handleScroll = (e : any) => {
    const div = e.target;
    if (div && div?.scrollTop) {
      const isAtBottom = div.scrollTop + div.clientHeight + 0.5 >= div?.scrollHeight;
      if (isAtBottom && salesPoints.length < count) {
        handleChangeFilters({ page: filters.page + 1 });
      }
    }
  };

  useEffect(() => {
    getUserVisits();
    postSalesPointsFiltered();
  }, []);

  useEffect(() => {
    if (searchParams) {
      const viewParams = searchParams.get('view');
      dispatch({
        type: SET_SALSES_POINTS_VIEW,
        payload: viewParams,
      });
      dispatch({
        type: SET_BACK_URL,
        payload: `/client?view=${viewParams}`,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (width && view !== 'map') {
      postSalesPointsFiltered();
    }
  }, [filters, view]);

  useEffect(() => {
    if (error?.data?.error === 'agentCode not found') {
      dispatch({
        type: VISIT_ERROR,
        payload: null,
      });
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'error',
          message: 'Vous n\'avez pas de code agent',
        },
      });
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <Header name="Base commerciale" />
      {width < 1024
        ? <>
          <MobileFilters />
          {view === 'card'
            && <div ref={divRef}
              onScroll={(e) => handleScroll(e)}
              className={styles.list}>
              <div className={styles.cards}>
                {salesPoints.map((s: SalesPointItem) => <Card
                  key={s._id}
                  card={s}
                />)}
              </div>
              {isLoading.find((s: string) => s === 'POST_SALSES_POINTS_FILTERED')
                && <div className={styles.loader}>
                  <Loader />
                </div>
              }
            </div>
          }
           {view === 'map'
                && <div className={styles['map-container-mobile']}>
                  <CustomMap/>
                </div>
              }
        </>
        : <div className={styles.desktop}>
          <div className={styles.header}>
            <div className={styles.title}>
              <h2>Points de ventes</h2>
              <div className={styles.views}>
                <button
                  onClick={() => handleChangeView('list')}
                  className={searchParams.get('view') === 'list' ? styles.active : ''}
                >
                  <MdFormatListBulleted />
                </button>
                <button
                  onClick={() => handleChangeView('card')}
                  className={searchParams.get('view') === 'card' ? styles.active : ''}
                >
                  <BiSolidGridAlt />
                </button>
                <button
                  onClick={() => handleChangeView('map')}
                  className={searchParams.get('view') === 'map' ? styles.active : ''}
                >
                  <MdMap />
                </button>
              </div>
            </div>
            <div className={styles['filters-container']}>
              <div className={styles.filters}>
                {lists?.customersStatus
                && <>
                  <div className={styles.filter}>
                    <DropDown
                      label="Types"
                      values={filters.customerStatus}
                      handleChange={(values: string[]) => handleChangeFilters({
                        customerStatus: values,
                        page: 0,
                      })}
                      options={lists.customersStatus}
                    />
                  </div>
                  <div className={styles.filter}>
                    <DropDown
                      label="Départements"
                      values={filters.departements}
                      handleChange={(values: string[]) => handleChangeFilters({
                        departements: values,
                        page: 0,
                      })}
                      options={lists.departements}
                    />
                  </div>
                  <div className={styles.filter}>
                    <DropDown
                      label="Villes"
                      values={filters.cities}
                      isSearch
                      allOptions='Toutes'
                      handleChange={(values: string[]) => handleChangeFilters({
                        cities: values,
                        page: 0,
                      })}
                      options={lists.cities}
                    />
                  </div>
                </>
                }
              </div>
              <div className={styles.search}>
                <Search
                  handleChangeFilters={handleChangeFilters}
                  search={filters.search}
                />
              </div>
            </div>
          </div>
            <div className={styles.content}>
              {(view === 'card' || view === 'list')
                && <div className={styles['list-container']}>
                  {isLoading.find((s: string) => s === 'POST_SALSES_POINTS_FILTERED')
                    ? <div className={styles.loader}>
                      <Loader />
                    </div>
                    : <>
                      {view === 'card' && <div className={styles.cards}>
                        {salesPoints.map((s: SalesPointItem) => <Card
                        key={s._id}
                        card={s}
                      />)}
                    </div>}
                    {view === 'list' && <div className={styles.list}>
                        <div className={`${styles.row} ${styles.labels}`}>
                          <div className={styles.col}>
                            <p>Nom du point de vente</p>
                          </div>
                          <div className={styles.col}>
                            <p>Adresse</p>
                          </div>
                          <div className={styles.col}>
                            <p>Numéro de téléphone</p>
                          </div>
                          <div className={styles.col}>
                            <p>Type</p>
                          </div>

                        </div>
                        {salesPoints.map((s: SalesPointItem) => <SalesPointRow
                        key={s._id}
                        row={s}
                        styles={styles}
                      />)}
                    </div>}
                    </>
                  }
                  {totalPages > 1
                    && <div className={styles.pagination}>
                      <Pagination
                        pageNumber={filters.page}
                        totalPages={totalPages}
                        onChangePage={(page) => handleChangeFilters({ page })}
                      />
                    </div>
                  }
                </div>
              }
              {view === 'map'
                && <div className={styles['map-container']}>
                  <CustomMap/>
                </div>
              }

            </div>
        </div>}
    </div>);
};

export default Base;
