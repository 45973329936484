import information from './images/informations.svg';
import commentaires from './images/commentaires.svg';
import tarification from './images/tarification.svg';
import facturation from './images/facturation.svg';
import admin from './images/admin.svg';
import meetings from './images/meetings.svg';

export const tabsIcons = {
  information,
  commentaires,
  tarification,
  facturation,
  admin,
  meetings,
};

export const salesPointsTabs = [
  {
    isProspect: true,
    label: 'Informations',
    icon: information,
  },

  {
    isProspect: false,
    label: 'Rendez-vous',
    icon: meetings,
  },
];
