/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useSelector } from 'react-redux';
import SalesPointForm from '../../SalesPointForm';

import styles from './infos.module.scss';

const Informations = () => {
  const salesPointsReducer = useSelector((d: any) => d.salesPointsReducer);
  const {
    salesPoint,
  } = salesPointsReducer;
  const company = salesPoint?.company;
  return (
    <div className={styles.container}>
      <div className={styles.col}>
        <SalesPointForm />
      </div>
        <div className={styles.col}>
          {company
          && <>
            <div className={styles.field}>
              <label>
                Actif
              </label>
              <p className={styles.bold}>{company?.isActive ? 'OUI' : 'NON'}</p>
            </div>
            {salesPoint?.agent?.profile?.firstName
              && <div className={styles.field}>
                <label>
                  Agent
                </label>
                <p className={styles.bold}>
                  {salesPoint?.agent?.profile?.firstName} {salesPoint?.agent?.profile?.lastName}
                </p>
              </div>
            }
            <div className={styles.field}>
              <label>
                Client
              </label>
              <p className={styles.bold}>{company?.name}</p>
            </div>
            <div className={styles.field}>
              <label>
                Nom propriétaire
              </label>
              <p className={styles.bold}>{company?.owner}</p>
            </div>
            <div className={styles.field}>
              <label>
                Numéro
              </label>
              <p className={styles.bold}>{company?.code}</p>
            </div>
            <div className={styles.field}>
              <label>
                Référence
              </label>
              <p className={styles.bold}>{company?.reference}</p>
            </div>
            <div className={styles.field}>
              <label>
                Email
              </label>
              <p className={styles.bold}>{company?.email}</p>
            </div>
            <div className={styles.field}>
              <label>
                N° de téléphone
              </label>
              <p className={styles.bold}>{company?.phone}</p>
            </div>
            <div className={styles.field}>
              <label>
                Adresse
              </label>
              <p className={styles.bold}>
                {company?.location?.address1}
                <br />
                {company?.location?.postalCode}
                {' '}
                {company?.location?.city}
              </p>
            </div>
          </>
        }
        <div className={styles.field}>
          <label>
            Point de vente
          </label>
        </div>
        <div className={styles.salesPoint}>
          <div className={styles.name}>
            <p className={styles.bold}>{salesPoint.name}</p>
            <p>
              {salesPoint?.location?.address1}
              {' '}
              {salesPoint?.location?.postalCode}
              {' '}
              {salesPoint?.location?.city}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informations;
