import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styles from './ProfileLink.module.scss';

const ProfileLink = () => {
  const { user } = useSelector((d: any) => d.authReducer);
  const location = useLocation();
  const path = location.pathname + location.search;
  return (
    <Link
      className={`${styles.container} ${path === '/profile' ? styles.active : ''}`}
      to="/profile"
    >
      {user?.picture?.path
        ? <img
          src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`}
          alt={user.profile.lastName}
        />
        : <p>{user?.profile.firstName[0]}.{user?.profile.lastName[0]}</p>
      }
    </Link>
  );
};

export default ProfileLink;
