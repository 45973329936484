/* eslint-disable no-restricted-syntax */
import React, {
  useState, useRef, useEffect, ReactNode,
} from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa6';

import styles from './panel.module.scss';

const SalePointPanel = (
  {
    isOpen = false,
    children,
    label = '',
    icon = '',
  }
  :
  {
    isOpen: Boolean,
    children: ReactNode,
    label: string,
    icon: string,
  },
) => {
  const contentRef = useRef<any>(null);
  const [panelIsOpen, setPanelIsOpen] = useState(isOpen);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (contentRef.current) {
        const { height } = contentRef.current.getBoundingClientRect();
        if (height !== contentHeight) {
          setContentHeight(height);
        }
      }
    }, 500); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, [contentHeight]);
  const height = contentHeight ? contentHeight + 30 : 0;

  return (
      <div className={styles.panel}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <img src={icon} alt="information"/>
            </div>
            <h3>{label}</h3>
          </div>
          <button
            onClick={() => setPanelIsOpen(!panelIsOpen)}
            className={styles.toggle}>
            { panelIsOpen ? <FaChevronUp /> : <FaChevronDown />}
          </button>
        </div>
        <div
          className={styles['panel-content']}
          style={{
            height: panelIsOpen && height ? height : 0,
          }}
        >
          <div ref={contentRef}>
            {children}
          </div>
        </div>
      </div>
  );
};

export default SalePointPanel;
