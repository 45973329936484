import React, { useEffect } from 'react';
import {
  Routes, Route, useNavigate, useSearchParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowSize } from 'usehooks-ts';
import { getProfileAction } from './actions/auth';

import Layout from './components/Layout';

import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Loader from './components/Loader';
import Toaster from './lib/Toaster';
import Base from './pages/Base';
import Users from './pages/Users';
import Visits from './pages/Visits';
import { useAppDispatch } from './reducers';
import { SET_SALSES_POINTS_FILTERS } from './actions/types';
import User from './pages/User';
import Profile from './pages/Profile';
import SalesPoint from './pages/SalesPoint';
import Archived from './pages/Archived';
import ArchivedVisits from './pages/ArchivedVisits';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getProfileAction(dispatch).catch(() => {
      navigate('/');
    });
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { user } = useSelector((store: any) => store.authReducer);
  const { filters } = useSelector((store: any) => store.salesPointsReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_SALSES_POINTS_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  function handleChangeView(string : string) {
    searchParams.set('view', string);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (width < 1024) {
      handleChangeView('card');
      handleChangeFilters({ limit: 50 });
    } else {
      handleChangeFilters({ limit: 12 });
    }
  }, [width]);

  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {user?.role && (
          <Route
            path="*"
            element={(
              <Layout key="app" role={user?.role}>
                <Routes>
                  <Route path="/client/:id" element={<SalesPoint />} />
                  <Route path="/client" element={<Base />} />
                  <Route path="/visits" element={<Visits />} />
                  <Route path="/archived/:date" element={<ArchivedVisits />} />
                  <Route path="/archived" element={<Archived />} />
                  <Route path="/notifs" element={<h1>notifs</h1>} />
                  <Route path="/profile" element={<Profile />} />
                  {user?.role === 'Admin' && (
                    <>
                      <Route path="/team/create" element={<User />} />
                      <Route path="/team/:id" element={<User />} />
                      <Route path="/team" element={<Users />} />
                    </>
                  )}
                </Routes>
              </Layout>
            )}
          />
        )}
        <Route path="*" element={<Redirect />} />
      </Routes>
      <Toaster />
    </>
  );
};

export default AppRoutes;
