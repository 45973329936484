/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GoArrowLeft } from 'react-icons/go';
import Header from '../../components/Header';
import { useAppDispatch } from '../../reducers';
import styles from './visit.module.scss';
import { GET_SALSES_POINT } from '../../actions/types';
import { getUserArchivedVisitsAction } from '../../actions/visits';
import { IVisit } from '../../types/visits';
import Card from '../../components/Card';

const ArchivedVisits = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const date = useParams()?.date;
  const formatedDate = date && new Date(date).toLocaleDateString('fr');
  const { archivedVisits } = useSelector((d: any) => d.visitsReducer);
  const dateVisits = date && archivedVisits?.filter(
    (a :IVisit) => a.archivedAt && a?.archivedAt.toString() === date,
  );

  const getUserArchivedVisits = useCallback(() => {
    getUserArchivedVisitsAction(dispatch);
    dispatch({
      type: GET_SALSES_POINT,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    getUserArchivedVisits();
  }, []);

  return (
    <div className={styles.container}>
      <Header name="Ma tournée" />
      <div className={styles.header}>
        <button
          type="button"
          onClick={() => navigate('/archived')}
          className={styles.back}>
          <GoArrowLeft />
          <p>RETOUR AUX Anciennes tournées</p>
        </button>
        <h2>Tournée du {formatedDate}</h2>
      </div>
      <div className={`${styles.list}`}>
        {dateVisits.map((v: IVisit) => <Card key={v._id} card={v.salesPoint} isArchived />)}
      </div>
    </div>);
};

export default ArchivedVisits;
