import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { getSalesPointsListAction } from '../../actions/salesPoints';
import logo from '../../assets/images/logo-full.svg';
import { useAppDispatch } from '../../reducers';
import ProfileLink from '../ProfileLink';
import base from './images/base.svg';
import base_active from './images/base_active.svg';
import list from './images/list.svg';
import list_active from './images/list_active.svg';
import map from './images/map.svg';
import map_active from './images/map_active.svg';
// import notif from './images/notif.svg';
// import notif_active from './images/notif_active.svg';
import styles from './nav.module.scss';

type LinkType = {
  label: string,
  link: string,
  icons?: string[],
};

const Nav = () => {
  const { user } = useSelector((d: any) => d.authReducer);
  const { visits } = useSelector((d: any) => d.visitsReducer);
  const { view, backUrl } = useSelector((d: any) => d.salesPointsReducer);
  const notVisited = visits.filter((v: any) => !v.isVisited)?.length;
  const dispatch = useAppDispatch();

  const getLists = useCallback(() => {
    getSalesPointsListAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getLists();
  }, []);

  const mobileLinks : LinkType[] = [
    {
      label: 'Base',
      link: '/client?view=card',
      icons: [base, base_active],
    },
    {
      label: 'Liste',
      link: '/visits',
      icons: [list, list_active],
    },
    {
      label: 'Carte',
      link: '/client?view=map',
      icons: [map, map_active],
    },
    // {
    //   label: 'Notif',
    //   link: '/notifs',
    //   icons: [notif, notif_active],
    // },
  ];
  const location = useLocation();
  const path = location.pathname + location.search;
  return (
    <div className={styles.container}>
      <div className={styles.mobile}>
        {mobileLinks.map((l) => <NavLink
          key={l.label}
          to={l.link}
          className={path === l.link ? styles.active : ''}
        >
        <div className={styles.link}>
          <div className={styles.icon}>
            {l.icons
              && <>
                <img src={l.icons[0]} className={styles.img}/>
                <img src={l.icons[1]} className={styles['active-img']}/>
              </>
            }
          </div>
          <p>
            {l.label}
          </p>
        </div>
        </NavLink>)}
      </div>
      <div className={styles.desktop}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={styles.links}>
            <NavLink
              to={`/client?view=${view}`}
              className={path.includes('/client') && !backUrl?.includes('/visits') ? styles.active : ''}
            >
              Points de vente
          </NavLink>
          {user.role === 'Admin'
            && <NavLink
                to={'/team'}
                className={path.includes('/team') ? styles.active : ''}
              >
              Équipe
            </NavLink>
          }
          <NavLink
            to={'/archived'}
            className={path.includes('/archived') ? styles.active : ''}
          >
            Anciennes tournées
          </NavLink>
        </div>
        <div className={styles.user}>
          <NavLink
            to={'/visits'}
            className={`${styles.tour} ${path === '/visits' || backUrl?.includes('/visits') ? styles.active : ''}`}
          >
            <p>ma tournée</p>
            {notVisited > 0
              && <div className={styles.count}>
               {notVisited}
              </div>
            }
          </NavLink>
          <ProfileLink />
          {/* <NavLink
              to={'/notifs'}
              className={`${styles.notifs} ${path === '/notifs' ? styles.active : ''}`}
            >
            <img src={notif} alt="notifs" />
          </NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default Nav;
