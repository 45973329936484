import {
  GET_SALSES_POINTS_LISTS,
  POST_SALSES_POINTS_FILTERED,
  SET_SALSES_POINTS_FILTERS,
  SET_SALSES_POINTS_VIEW,
  SALES_POINTS_ERROR,
  SALES_POINTS_LOADING,
  UPDATE_SALES_POINT,
  GET_SALSES_POINT,
  SET_BACK_URL,
  GET_USER_VISITS,
  SET_MAP_CENTER,
  GET_SALSES_POINT_LOCATION,
  GET_SALSES_POINT_MAP,
  GET_SALSES_POINT_APPOINTMENTS,
} from '../actions/types';
import { ActionReducer } from '../types';
import { AppointmentType, Lists, SalesPointItem } from '../types/salesPoints';

interface SalesPointsState {
  isLoading: string[],
  error: string | null,
  view: string,
  backUrl: string | null,
  salesPoints: SalesPointItem[],
  salesPoint: SalesPointItem | null,
  salesPointsMarkers: SalesPointItem[],
  lists: Lists | null,
  mapCenter: number[] | null,
  appointments: AppointmentType[],
  filters: {
    search: string;
    sort: string;
    sortDir: string;
    cities: never[];
    departements: string[];
    customerStatus: string[];
    page: number;
    limit: number;
  }
  count: number | null,
}

const DEFAULT_STATE: SalesPointsState = {
  filters: {
    search: '',
    sort: 'name',
    sortDir: 'asc',
    cities: [],
    departements: [],
    customerStatus: ['active-customer', 'active-lead', 'lead'],
    page: 0,
    limit: 50,
  },
  mapCenter: null,
  appointments: [],
  count: 0,
  view: 'card',
  salesPoints: [],
  salesPointsMarkers: [],
  salesPoint: null,
  backUrl: '/client?view=card',
  lists: null,
  isLoading: [],
  error: null,
};

// eslint-disable-next-line default-param-last
const salesPointsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  let salesPoints = [...state.salesPoints];
  const salesPointsMarkers = [...state.salesPointsMarkers];
  const salesPointIndex = salesPoints.findIndex((s) => s._id === action.payload?._id);
  const salesPointsMarkersIndex = salesPointsMarkers.findIndex(
    (s) => s._id === action.payload?._id,
  );
  switch (action.type) {
    case SALES_POINTS_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SALES_POINTS_ERROR:
      updatedState = {
        ...state,
        error: action.payload,
      };
      break;
    case SET_MAP_CENTER:
      updatedState = {
        ...state,
        mapCenter: action.payload,
      };
      break;
    case SET_SALSES_POINTS_FILTERS:
      updatedState = {
        ...state,
        filters: { ...action.payload },
      };
      break;
    case GET_USER_VISITS:
      updatedState = {
        ...state,
        salesPoint: null,
      };
      break;
    case GET_SALSES_POINT:
      updatedState = {
        ...state,
        salesPoint: action.payload,
      };
      break;
    case SET_BACK_URL:
      updatedState = {
        ...state,
        backUrl: action.payload,
      };
      break;
    case SET_SALSES_POINTS_VIEW:
      updatedState = {
        ...state,
        view: action.payload,
      };
      break;
    case GET_SALSES_POINTS_LISTS:
      updatedState = {
        ...state,
        lists: { ...action.payload },
      };
      break;
    case GET_SALSES_POINT_LOCATION:
      if (salesPointIndex !== -1) {
        salesPoints[salesPointIndex] = action.payload;
      }
      updatedState = {
        ...state,
        salesPoints,
      };
      break;
    case UPDATE_SALES_POINT:
      if (salesPointIndex !== -1) {
        salesPoints[salesPointIndex] = action.payload;
      }
      if (salesPointsMarkersIndex !== -1) {
        salesPointsMarkers[salesPointsMarkersIndex] = action.payload;
      }
      updatedState = {
        ...state,
        salesPoints,
        salesPointsMarkers,
        salesPoint: action.payload,
      };
      break;
    case GET_SALSES_POINT_MAP:
      updatedState = {
        ...state,
        salesPointsMarkers: action.payload,
      };
      break;
    case GET_SALSES_POINT_APPOINTMENTS:
      updatedState = {
        ...state,
        appointments: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case POST_SALSES_POINTS_FILTERED:
      if (state.filters.page === 0 || state.filters.limit < 50) {
        salesPoints = [...action.payload.salesPoints];
      } else {
        salesPoints = [...salesPoints, ...action.payload.salesPoints];
      }
      updatedState = {
        ...state,
        salesPoints,
        count: action.payload.count,
        salesPoint: null,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default salesPointsReducer;
