/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { useAppDispatch } from '../../reducers';
import styles from './visit.module.scss';
import { GET_SALSES_POINT } from '../../actions/types';
import { getUserArchivedVisitsAction } from '../../actions/visits';
import { IVisit } from '../../types/visits';

type ArchivedItem = { date: Date, count: number };

const Archived = () => {
  const dispatch = useAppDispatch();
  const [visitByDate, setVisitByDate] = useState<ArchivedItem[]>([]);
  const { archivedVisits } = useSelector((d: any) => d.visitsReducer);

  const getUserArchivedVisits = useCallback(() => {
    getUserArchivedVisitsAction(dispatch);
    dispatch({
      type: GET_SALSES_POINT,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    getUserArchivedVisits();
  }, []);

  useEffect(() => {
    if (archivedVisits) {
      const results : ArchivedItem[] = [];
      archivedVisits.forEach((v: IVisit) => {
        if (!results.find((r: ArchivedItem) => r.date === v.archivedAt)) {
          results.push({
            date: v.archivedAt || new Date(),
            count: archivedVisits.filter((a: IVisit) => a.archivedAt === v.archivedAt).length,
          });
        }
      });
      setVisitByDate(results);
    }
  }, [archivedVisits]);

  return (
    <div className={styles.container}>
      <Header name="Ma tournée" />
      <div className={styles.header}>
        <h2>Anciennes tournées</h2>
      </div>
      <div className={`${styles.list} ${styles.labels}`}>
        <div className={styles.row}>
          <div className={styles.col}>
            <p>Date de tournée</p>
          </div>
          <div className={styles.col}>
            <p>Nb de point de ventes visités</p>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {visitByDate.map((v: ArchivedItem) => {
          const date = v.date && new Date(v.date).toLocaleDateString('fr');
          return (
            <Link
              key={`${v.date}`}
              to={`/archived/${v.date}`}
              className={styles.row}
            >
              <div className={styles.col}>
                <p>{date}</p>
              </div>
              <div className={styles.col}>
                <p>{v.count}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>);
};

export default Archived;
