import React from 'react';
import { FaCheck, FaMinus, FaPlus } from 'react-icons/fa6';
import { LuMapPinOff } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSalesPointLocationAction } from '../../actions/salesPoints';
import { SET_BACK_URL } from '../../actions/types';
import { createVisitAction } from '../../actions/visits';
import { useAppDispatch } from '../../reducers';
import { OptionType } from '../../types';
import { SalesPointItem } from '../../types/salesPoints';

const SalesPointRow = ({
  row,
  styles,
}:{
  row: SalesPointItem,
  styles: any,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { lists } = useSelector((d: any) => d.salesPointsReducer);
  const status = lists?.customersStatus?.find((c: OptionType) => c.value === row.customerStatus);
  const { visits } = useSelector((d: any) => d.visitsReducer);
  const visitFound = visits.find((d: any) => d?.salesPoint?._id === row._id);

  function handleVisit(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!visitFound) {
      createVisitAction(dispatch, { salesPoint: row._id });
    }
    if (visitFound) {
      navigate('/visits');
    }
  }

  function navigateToSalesPoint(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: SET_BACK_URL,
      payload: `${window.location?.pathname}${window.location.search}`,
    });
    navigate(`/client/${row._id}`);
  }

  async function getSalesPointLocation(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    await getSalesPointLocationAction(dispatch, row._id);
  }

  return (
    <div className={styles.row}
      onClick={(e) => navigateToSalesPoint(e)}
    >
      <div className={styles.col}>
        <p className={styles.name}>{row?.company?.name || row.name}</p>
      </div>
      <div className={styles.col}>
        {!row.location.lat && !row.location?.lon && <button
          type="button"
          className={styles.pin}
          onClick={(e) => getSalesPointLocation(e)}
        >
            <LuMapPinOff />
        </button>}
        <p className={styles.address}>
        {row?.location?.address1}
        {' '}
        {row?.location?.postalCode} {row?.location?.city}
        </p>
      </div>
      <div className={styles.col}>
        <p>{row?.company?.phone}</p>
      </div>
      <div className={`${styles.col} ${styles.status}`}>
        {status?.value === 'active-customer'
          && <span>
            Point de vente
            {' '}
            <span style={{ color: status.color }}>
              actif
            </span>
          </span>
        }
        {status?.value === 'customer'
          && <span>
            Point de vente
            {' '}
            <span style={{ color: status.color }}>
              inactif
            </span>
          </span>
        }
        {status?.value === 'lead'
          && <span>
            Prospect
            {' '}
            <span style={{ color: status.color }}>
              inactif
            </span>
          </span>
        }
        {status?.value === 'active-lead'
          && <span>
            Prospect
            {' '}
            <span style={{ color: status.color }}>
              actif
            </span>
          </span>
        }
        <button
          type="button"
          className={`${styles.toggle} ${visitFound ? styles.active : ''}`}
          onClick={(e) => handleVisit(e)}
        >
          {!visitFound && <FaPlus />}
          {visitFound?.isVisited && <FaCheck />}
          {visitFound?.isVisited === false && <FaMinus />}
        </button>
      </div>
    </div>
  );
};

export default SalesPointRow;
