import { Dispatch } from 'redux';
import { getData, postData, putData } from '.';
import {
  GET_SALSES_POINT,
  GET_SALSES_POINTS_LISTS,
  POST_SALSES_POINTS_FILTERED,
  GET_SALSES_POINT_MAP,
  GET_SALSES_POINT_LOCATION,
  SALES_POINTS_ERROR,
  SALES_POINTS_LOADING,
  UPDATE_SALES_POINT,
  SET_TOAST,
} from './types';

export interface RequestFunction {
  (
    dispatch: Dispatch,
    data: any,
  ) : any
}

export const getSalesPointsListAction = async (dispatch: Dispatch) => {
  const url = '/salespoints/lists';
  const response = await getData(SALES_POINTS_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: GET_SALSES_POINTS_LISTS,
      payload: response.data,
    });
  }
};

export const postSalesPointsFilteredAction: RequestFunction = async (dispatch, data) => {
  dispatch({
    type: SALES_POINTS_LOADING,
    payload: POST_SALSES_POINTS_FILTERED,
  });
  const url = '/salespoints/filtered';
  const response = await postData(SALES_POINTS_ERROR, url, dispatch, data, true);
  if (response?.data) {
    dispatch({
      type: POST_SALSES_POINTS_FILTERED,
      payload: {
        ...response.data,
        page: data.page,
      },
    });
  }
  return response;
};

export const getSalesPointAction = async (dispatch: Dispatch, id: string) => {
  const url = `/salespoints/${id}`;
  const response = await getData(SALES_POINTS_ERROR, url, dispatch, true);
  if (response?.data?.salesPoint) {
    dispatch({
      type: GET_SALSES_POINT,
      payload: response.data.salesPoint,
    });
  }
};

export const getSalesPointLocationAction = async (dispatch: Dispatch, id: string) => {
  const url = `/salespoints/${id}`;
  const response = await getData(SALES_POINTS_ERROR, url, dispatch, true);
  if (response?.data?.salesPoint) {
    dispatch({
      type: GET_SALSES_POINT_LOCATION,
      payload: response.data.salesPoint,
    });
  }
};

export const updateSalesPointAction = async (dispatch: Dispatch, id: string, data: any) => {
  const url = `/salespoints/${id}`;
  const response = await putData(SALES_POINTS_ERROR, url, dispatch, data, true);

  if (response.data.salesPoint) {
    dispatch({
      type: UPDATE_SALES_POINT,
      payload: response.data.salesPoint,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Le point de vente a été modifié',
      },
    });
  }
  return response;
};

export const postSalesPointsMapAction: RequestFunction = async (dispatch, data) => {
  const url = '/salespoints/map';
  const response = await postData(SALES_POINTS_ERROR, url, dispatch, data, true);
  if (response?.data) {
    dispatch({
      type: GET_SALSES_POINT_MAP,
      payload: response.data.salesPoints,
    });
  }
  return response;
};
