/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState, useEffect } from 'react';
import L, { Icon } from 'leaflet';
import {
  MapContainer, Marker, TileLayer, useMap, useMapEvent, Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './map.scss';
import { useWindowSize } from 'usehooks-ts';
import { useSelector } from 'react-redux';
import MarkerClusterGroup from 'react-leaflet-cluster';
import customerActive from './icons/customer_active.png';
import customerNotActive from './icons/customer_not_active.png';
import leadNotActive from './icons/lead_not_active.png';
import leadActive from './icons/lead_active.png';
import pin from './icons/pin.png';
import { useAppDispatch } from '../../reducers';
import { postSalesPointsMapAction } from '../../actions/salesPoints';
import Card from '../Card';
import { SET_MAP_CENTER } from '../../actions/types';
import { calculateAverage } from '../../utils';

function MapEventHandlers({ callBack }) {
  const mapMoveEnd = useMapEvent('moveend', () => {
    const bounds = mapMoveEnd.getBounds();
    callBack(bounds);
  });
}

const CustomMarker = ({ marker }) => {
  let iconUrl = customerActive;
  if (marker.customerStatus === 'lead') {
    iconUrl = leadNotActive;
  }
  if (marker.customerStatus === 'active-lead') {
    iconUrl = leadActive;
  }
  if (marker.customerStatus === 'customer') {
    iconUrl = customerNotActive;
  }

  const icon = new Icon({
    iconUrl,
    iconSize: [26, 26], // size of the icon
    iconAnchor: [13, 13], // point of the icon which will correspond to marker's location
  });
  return <Marker
    icon={icon}
    position={[marker.location.lat, marker.location.lon]}
    title={marker.name}
  >
    <Popup>
      <Card card={marker}/>
    </Popup>
  </Marker>;
};

const UserMarker = ({ marker }) => {
  const iconUser = new Icon({
    iconUrl: pin,
    iconSize: [30, 30],
    iconAnchor: [0, 40],
  });
  return <Marker
    icon={iconUser}
    position={marker}
  >
  </Marker>;
};

const createClusterCustomIcon = (cluster) => L.divIcon({
  html: `<span>${cluster.getChildCount()}</span>`,
  className: 'custom-marker-cluster',
  iconSize: L.point(40, 40, true),
});

function UseMapLocation({
  callBack, mapCenter, center, clearCenter, setUserMarker,
}) {
  const map = useMap();
  useEffect(() => {
    if (!mapCenter) {
      map.locate().on('locationfound', (e) => {
        const latlng = e?.latlng;
        setUserMarker(latlng);
        console.log(mapCenter);
        callBack(latlng);
        map.flyTo(latlng, map.getZoom());
      });
    }
    if (center) {
      callBack(center);
      map.flyTo(center, 14);
      clearCenter();
    }
  }, [mapCenter, center]);
}

export default function CustomMap() {
  const mapRef = useRef();
  const { width, height } = useWindowSize();
  const {
    filters,
    salesPointsMarkers,
    mapCenter,
    lists,
  } = useSelector((store) => store.salesPointsReducer);
  const [position, setPosition] = useState(mapCenter || [47.216671, -1.55]);
  const [center, setCenter] = useState();
  const city = filters?.cities?.length === 1
  && lists.cities.find((c) => c.value === filters.cities[0]);
  const [bounds, setBounds] = useState();
  const [userMarker, setUserMarker] = useState();
  const dispatch = useAppDispatch();

  const getStyle = () => {
    const style = {
      width,
      height,
    };
    if (width > 1024) {
      style.height = height - 218;
    }
    return style;
  };

  const getMapBounds = async (b, isInit = false) => {
    const payload = {
      lat: [b._southWest.lat, b._northEast.lat],
      lon: [b._southWest.lng, b._northEast.lng],
    };
    setBounds(payload);
    const payloadCenter = [calculateAverage(payload.lat), calculateAverage(payload.lon)];
    if (!isInit) {
      dispatch({
        type: SET_MAP_CENTER,
        payload: payloadCenter,
      });
    }
  };

  const getMarkers = async () => {
    await postSalesPointsMapAction(dispatch, { ...bounds, ...filters });
  };

  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      if (bounds) {
        getMarkers();
      }
    }, 500);
    return () => clearTimeout(saveTimeout);
  }, [bounds, filters]);

  useEffect(() => {
    if (city) {
      const cityCenter = [
        calculateAverage(city.lat.filter((d) => d !== null)),
        calculateAverage(city.lon.filter((d) => d !== null)),
      ];
      setCenter(cityCenter);
    }
  }, [city]);

  return (
    <div ref={mapRef} className={'container'}>
      {width
      && <MapContainer
        center={position}
        zoom={18}
        maxZoom={19}
        scrollWheelZoom={true}
        whenReady={(e) => {
          getMapBounds(e.target.getBounds(), true);
        }}
        style={getStyle()}>
        <MapEventHandlers callBack={(b) => getMapBounds(b)}/>
        <UseMapLocation
          callBack={(p) => {
            setPosition(p);
          }}
          mapCenter={mapCenter}
          setUserMarker={setUserMarker}
          center={center}
          clearCenter={() => setCenter()}
        />
        <MarkerClusterGroup
          showCoverageOnHover={false}
          iconCreateFunction={createClusterCustomIcon}
          spiderfyOnMaxZoom={true}
          chunkedLoading
        >
          {salesPointsMarkers.length < 10000 && salesPointsMarkers?.map((marker) => (
            <CustomMarker key={marker._id}
              marker={marker}
            />
          ))}
        </MarkerClusterGroup>
          {userMarker && <UserMarker marker={userMarker} />}
        <TileLayer
          maxZoom={19}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
      }
    </div>
  );
}
