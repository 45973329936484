/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useWindowSize } from 'usehooks-ts';
import Card from '../../components/Card';
import Header from '../../components/Header';
import notVisitedImg from './images/notVisited.svg';
import visitedImg from './images/visited.svg';
import search from '../../assets/images/search.svg';
import { useAppDispatch } from '../../reducers';
import styles from './visit.module.scss';
import { getUserVisitsAction, deleteManyVisitsAction, archiveManyVisitsAction } from '../../actions/visits';
import { GET_SALSES_POINT, SET_VISIT_APPOINTMENT } from '../../actions/types';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import AppointmentForm from '../../components/AppointmentForm';

const Visits = () => {
  const dispatch = useAppDispatch();
  const appointmentModalRef = useRef<IModalHandle>(null);
  const { width } = useWindowSize();
  const { visits, appointment } = useSelector((d: any) => d.visitsReducer);
  const [inputSearch, setInputSearch] = useState('');

  const filtered = visits?.filter((v :any) => {
    if (v?.salesPoint?.name.toLowerCase().includes(inputSearch.toLowerCase())) {
      return v;
    }
    if (v?.salesPoint?.company?.name.toLowerCase().includes(inputSearch.toLowerCase())) {
      return v;
    }
    return null;
  });

  const visited = filtered.filter((v: any) => v.isVisited);
  const notVisited = filtered.filter((v: any) => !v.isVisited);

  const getUserVisits = useCallback(() => {
    getUserVisitsAction(dispatch);
    dispatch({
      type: GET_SALSES_POINT,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    getUserVisits();
  }, []);

  useEffect(() => {
    if (appointmentModalRef?.current) {
      if (appointment) {
        appointmentModalRef?.current.open();
      }
    }
  }, [appointment]);

  return (
    <>
      <div className={styles.container}>
        <Header name="Ma tournée" />
        {width < 1024
          ? <>
            <div className={styles.search}>
              <img src={search} alt="search"/>
              <input
                value={inputSearch}
                placeholder="Rechercher..."
                onChange={(e) => setInputSearch(e.target.value)}
              />
            </div>
            <div className={styles.list}>
              <div className={styles.title}>
                <p>À visiter ({notVisited.length})</p>
                <button
                  type="button"
                  onClick={() => deleteManyVisitsAction(dispatch, false)}
                >
                  <RiDeleteBinLine />
                  Effacer la liste
                </button>
              </div>
              <div className={styles.cards}>
                {notVisited?.map((s: any) => <Card
                  key={s._id}
                  card={s.salesPoint}
                  isVisit={true}
                />)}
              </div>

              <div className={styles.title}>
                <p>Déja visité ({visited.length})</p>
              </div>
              <div className={styles.cards}>
                {visited.filter((v: any) => v.isVisited).map((s: any) => <Card
                  key={s._id}
                  card={s.salesPoint}
                  isVisit={true}
                />)}
              </div>
            </div>
          </>
          : <div className={styles.desktop}>
              <div className={styles.col}>
                <div className={styles.header}>
                  <h2>Ma tournée</h2>
                  <div className={styles.search}>
                    <input
                      value={inputSearch}
                      placeholder="Rechercher..."
                      onChange={(e) => setInputSearch(e.target.value)}
                    />
                    <img src={search} alt="search"/>
                  </div>
                </div>
                <div className={styles.list}>
                  <div className={styles.title}>
                    <p>À visiter ({notVisited.length})</p>
                    <button
                      type="button"
                      onClick={() => deleteManyVisitsAction(dispatch, false)}
                    >
                      <RiDeleteBinLine />
                      Effacer la liste
                    </button>
                  </div>
                  {notVisited.length > 0
                    ? <div className={styles.cards}>
                    {notVisited?.map((s: any) => <Card
                      key={s._id}
                      card={s.salesPoint}
                      isVisit={true}
                    />)}
                  </div>
                    : <div className={styles.empty}>
                      <p>Ajoutez des points de ventes à votre tournée<br/>
                        pour les voir apparaitre ici</p>
                      <img src={notVisitedImg} alt="Ajoutez des points de ventes" />
                  </div>}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.list}>
                  <div className={styles.title}>
                    <p>Déja visité ({visited.length})</p>
                    <button
                      type="button"
                      onClick={() => archiveManyVisitsAction(dispatch, true)}
                    >
                      <RiDeleteBinLine />
                      Effacer la liste
                    </button>
                  </div>
                  {visited.length > 0
                    ? <div className={styles.cards}>
                    {visited.filter((v: any) => v.isVisited).map((s: any) => <Card
                      key={s._id}
                      card={s.salesPoint}
                      isVisit={true}
                    />)}
                  </div>
                    : <div className={styles.empty}>
                  <img src={visitedImg} alt="Points de ventes vistés" />
                  </div>}
                </div>
              </div>
          </div>}
      </div>);
      <ModalStandalone
        ref={appointmentModalRef}
        closeCallBack={() => {
          dispatch({
            type: SET_VISIT_APPOINTMENT,
            payload: null,
          });
        }}
      >
        <AppointmentForm
          close={() => {
            if (appointmentModalRef?.current) {
              appointmentModalRef?.current.close();
            }
            dispatch({
              type: SET_VISIT_APPOINTMENT,
              payload: null,
            });
          }}
        />
      </ModalStandalone>
    </>
  );
};

export default Visits;
