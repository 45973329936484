import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { postAppointmentAction } from '../../actions/appointment';
import { SET_TOAST } from '../../actions/types';
import { updateVisitAction } from '../../actions/visits';
import { ErrorField } from '../../lib/HooksFormFields';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect/Select';
import Textarea from '../../lib/HooksFormFields/Textarea';
import { useAppDispatch } from '../../reducers';
import styles from './AppointmentForm.module.scss';

const AppointmentForm = ({
  close,
}:{
  close: () => void,
}) => {
  const dispatch = useAppDispatch();
  const { appointment } = useSelector((d: any) => d.visitsReducer);
  const { lists } = useSelector((d: any) => d.salesPointsReducer);
  const {
    control, reset, formState: { errors }, handleSubmit,
  } = useForm<FieldValues>();

  useEffect(() => {
    if (appointment) {
      const data = {
        siret: appointment.salesPoint.siret,
      };
      reset(data);
    }
  }, [appointment]);

  async function saveAndClose() {
    await updateVisitAction(dispatch, appointment?._id, { isVisited: true });
    return close();
  }

  async function onSubmit(values: FieldValues) {
    const res = await postAppointmentAction(dispatch, values);
    console.log(res?.response?.data?.error);
    if (res?.response?.data?.error) {
      return dispatch({
        type: SET_TOAST,
        payload: {
          type: 'error',
          message: res?.response?.data?.error,
        },
      });
    }
    await saveAndClose();
    return dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Le rendez-vous à été crée',
      },
    });
  }
  console.log(errors);
  return (
    <div className={styles.container}>
      <h4>Ajouter un commentaire</h4>
      <div className={styles.name}>
        <h4>{appointment?.salesPoint?.name}</h4>
        <p>
          {appointment?.salesPoint?.location?.address1},{' '}
          {appointment?.salesPoint?.location?.postalCode}{' '}
          {appointment?.salesPoint?.location?.city}
        </p>
      </div>
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles['container-field']}>
              <StyledSelect
                name="type"
                control={control}
                rules={{ required: 'Le type de rendez-vous est requis' }}
                className="primary"
                options={lists.appointmentsOptions || []}
                label="Type de rendez-vous"
              />
                {(typeof errors.type?.message === 'string')
                && <div className={styles.error}>
                <ErrorField
                message={errors.type.message || ''}
              />
              </div>}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles['container-field']}>
              <StyledSelect
                name="result"
                control={control}
                rules={{ required: 'Le résultat est requis' }}
                className="primary"
                options={lists.appointmentsResultsOptions || []}
                label="Résultat"
              />
                {(typeof errors.result?.message === 'string')
                && <div className={styles.error}>
                <ErrorField
                message={errors.result.message || ''}
              />
              </div>}
            </div>
          </div>
        </div>
        <div className={styles['container-field']}>
          <Textarea
           name="comment"
           control={control}
           rules={{ }}
           className="primary"
          />
        </div>

      </div>
      <div className={styles.actions}>
        <button
          className={styles.btn}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          Valider
        </button>
        <button
          type="button"
          onClick={() => saveAndClose()}
        >
          Ne pas ajouter de commentaire
        </button>
      </div>
    </div>
  );
};

export default AppointmentForm;
