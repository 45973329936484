/* eslint-disable no-dupe-keys */
const styleBase = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#fcfcfc',
    // overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: '#fcfcfc',
    borderWidth: '1px',
    backgroundColor: '#fcfcfc',
    minHeight: '37px',
    borderRadius: '10px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#005763',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, color: '#222429', fontSize: '14px' }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: '15px',
    fontFamily: 'Montserrat-SemiBold',
    backgroundColor: '#E2F465',
  }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 4px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#dfe5e6' : '',
    cursor: 'pointer',
    color: state.isSelected ? '#181C1F' : '#005763',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: '#dfe5e6',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '37px',
  }),
};

const stylePrimary = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    maxWidth: '95%',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    // overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: 'transparent',
    borderRadius: '10px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#005763',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '13px',
    backgroundColor: state.isSelected ? '#005763' : '#f5f5f5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#005763' : '#f5f5f5',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '39px',
  }),
};

const styleDark = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#DEE0EA',
    minHeight: '39px',
    borderRadius: '10px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#005763',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '13px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '13px',
    backgroundColor: state.isSelected ? '#005763' : '',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#005763' : '#F4F7FE',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '39px',
  }),
};

const styleTonic = (color) => ({
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    // overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: color,
    borderWidth: '1px',
    backgroundColor: `${color}30`,
    minHeight: '37px',
    borderRadius: '10px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#005763',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, color, fontSize: '13px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '13px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color,
    padding: '8px 4px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '13px',
    backgroundColor: state.isSelected ? color : '',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? color : '#F4F7FE',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '37px',
  }),
});

export {
  styleBase,
  stylePrimary,
  styleDark,
  styleTonic,
};
