import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { getSalesPointAction, getSalesPointsListAction } from '../../actions/salesPoints';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../reducers';
import SalesPointMobile from './Mobile';
import SalesPointDesktop from './Desktop';
import styles from './salesPoints.module.scss';
import { getAppointmentsAction } from '../../actions/appointment';
import { GET_SALSES_POINT_APPOINTMENTS } from '../../actions/types';

export default function SalesPoint() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const salesPointsReducer = useSelector((d: any) => d.salesPointsReducer);
  const {
    salesPoint, backUrl, isLoading,
  } = salesPointsReducer;

  const getSalesPoint = useCallback(() => {
    if (params?.id) {
      getSalesPointAction(dispatch, params?.id);
      getSalesPointsListAction(dispatch);
      dispatch({
        type: GET_SALSES_POINT_APPOINTMENTS,
        payload: [],
      });
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    getSalesPoint();
  }, []);

  useEffect(() => {
    if (salesPoint?.siret) {
      getAppointmentsAction(dispatch, salesPoint?.siret);
    }
  }, [salesPoint?.siret]);

  return (
    <div className={styles.container}>
      {salesPoint && !isLoading.find((l: string) => l === GET_SALSES_POINT_APPOINTMENTS)
        ? <>
          {width > 1024
            ? <SalesPointDesktop
                salesPoint={salesPoint}
                goBack={() => navigate(backUrl)}
              />
            : <SalesPointMobile
                salesPoint={salesPoint}
                goBack={() => navigate(backUrl)}
            />
          }
      </>
        : <div className={styles.loader}>
          <Loader />
      </div>
      }
    </div>
  );
}
