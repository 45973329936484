import {
  CREATE_USER,
  DELETE_USER,
  GET_USER,
  POST_USERS_FILTERED,
  SET_USERS_FILTERS,
  UPDATE_USER,
  USERS_ERROR,
  USERS_LOADING,
} from '../actions/types';
import { ActionReducer, OptionType } from '../types';
import { IUser, UsersLists } from '../types/user';

const roles : OptionType[] = [
  { value: 'Admin', label: 'Administrateur' },
  { value: 'Member', label: 'Membre' },
];

interface UsersState {
  isLoading: string[],
  error: string | null,
  users: IUser[],
  lists: UsersLists | null,
  user: IUser | null,
  filters: {
    search: string;
    sort: string;
    sortDir: string;
    roles: string[];
    page: number;
    limit: number;
  }
  count: number | null,
}

const DEFAULT_STATE: UsersState = {
  filters: {
    search: '',
    sort: 'profile.firstName',
    sortDir: 'asc',
    roles: [],
    page: 0,
    limit: 10,
  },
  count: 0,
  users: [],
  user: null,
  lists: {
    roles,
  },
  isLoading: [],
  error: null,
};

// eslint-disable-next-line default-param-last
const usersReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  let users = [...state.users];
  const userIndex = users.findIndex((v: any) => v._id === action.payload?._id);
  switch (action.type) {
    case USERS_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;

    case GET_USER:
      updatedState = {
        ...state,
        user: action.payload,
      };
      break;
    case USERS_ERROR:
      updatedState = {
        ...state,
        error: action.payload,
      };
      break;
    case SET_USERS_FILTERS:
      updatedState = {
        ...state,
        filters: { ...action.payload },
      };
      break;
    case POST_USERS_FILTERED:

      users = [...action.payload.users];

      updatedState = {
        ...state,
        users,
        user: null,
        count: action.payload.count,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case CREATE_USER:
      updatedState = {
        ...state,
        user: action.payload,
        users: [action.payload, ...users],
      };
      break;
    case UPDATE_USER:
      if (userIndex !== -1) {
        users[userIndex] = action.payload;
      }
      updatedState = {
        ...state,
        user: action.payload,
        users,
      };
      break;
    case DELETE_USER:
      updatedState = {
        ...state,
        user: null,
        users: users.filter((v: any) => v?._id !== action.payload),
      };
      break;

    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default usersReducer;
