import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RiDeleteBinLine } from 'react-icons/ri';
import { LuMapPinOff } from 'react-icons/lu';
import { FaCheck, FaMinus, FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { OptionType } from '../../types';
import { SalesPointItem } from '../../types/salesPoints';
import hexToRgba from '../../utils';
import styles from './card.module.scss';
import { useAppDispatch } from '../../reducers';
import { createVisitAction, updateVisitAction } from '../../actions/visits';
import { SET_BACK_URL, SET_VISIT_APPOINTMENT } from '../../actions/types';
import { getSalesPointLocationAction } from '../../actions/salesPoints';

const Card = ({
  card,
  isVisit = false,
  isArchived = false,
}:{
  card: SalesPointItem,
  isVisit?: Boolean,
  isArchived?: Boolean,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const height = cardRef?.current ? (cardRef.current.getBoundingClientRect().height / 2) - 20 : 30;
  const { lists } = useSelector((d: any) => d.salesPointsReducer);
  const status = lists?.customersStatus?.find((c: OptionType) => c.value === card.customerStatus);
  const { visits } = useSelector((d: any) => d.visitsReducer);
  const visitFound = visits.find((d: any) => d?.salesPoint?._id === card._id);
  const archivedAt = visitFound?.updatedAt
    && new Date(new Date(visitFound?.updatedAt).setHours(1, 0, 0, 0));

  function handleVisit(e : React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (visitFound) {
      return updateVisitAction(dispatch, visitFound?._id, { archivedAt });
    }
    return createVisitAction(dispatch, { salesPoint: card?._id });
  }

  function handleVisitStatus(e : React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!visitFound) {
      return;
    }
    if (!visitFound?.isVisited) {
      console.log(visitFound.salesPoint, SET_VISIT_APPOINTMENT);
      if (visitFound.salesPoint?.siret) {
        dispatch({
          type: SET_VISIT_APPOINTMENT,
          payload: visitFound,
        });
      } else {
        updateVisitAction(dispatch, visitFound?._id, { isVisited: true });
      }
    } else {
      updateVisitAction(dispatch, visitFound?._id, { archivedAt });
    }
  }

  function deleteVisit(e : React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    updateVisitAction(dispatch, visitFound?._id, { archivedAt });
  }

  function navigateToSalesPoint(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    dispatch({
      type: SET_BACK_URL,
      payload: `${window.location?.pathname}${window.location.search}`,
    });
    navigate(`/client/${card._id}`);
  }

  async function getSalesPointLocation(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    await getSalesPointLocationAction(dispatch, card._id);
  }

  return (
    <div
      ref={cardRef}
      className={`${styles.card} ${isVisit && styles['visit-card']}`}
      onClick={(e) => navigateToSalesPoint(e)}
    >
      {status?.color
        && <div className={styles.icon} style={{
          backgroundColor: hexToRgba(status?.color, 0.3),
        }}>
          {card?.picture?.path
            && <img
              src={`${process.env.REACT_APP_API_URL}/public/${card?.picture?.path}`}
              className={styles.img}
            />
          }
        </div>
      }
      <div className={styles.infos}>
      <p className={styles.status}>
        {status?.value === 'active-customer'
          && <span>
          Point de vente
          {' '}
          <span style={{ color: status.color }}>
            actif
          </span>
        </span>
        }
        {status?.value === 'customer'
          && <span>
            Point de vente
            {' '}
            <span style={{ color: status.color }}>
              inactif
            </span>
          </span>
        }
        {status?.value === 'lead'
          && <span>
            Prospect
            {' '}
            <span style={{ color: status.color }}>
              inactif
            </span>
          </span>
        }
         {status?.value === 'active-lead'
          && <span>
            Prospect
            {' '}
            <span style={{ color: status.color }}>
              actif
            </span>
          </span>
        }
      </p>
      <h4>{card?.company?.name || card.name}</h4>
      <p className={styles.address}>
        {card?.location?.address1}<br/>
        {card?.location?.postalCode} {card?.location?.city}</p>
      </div>
      {!isArchived
        && <>
        {!isVisit
          && <button
            type="button"
            className={`${styles.toggle} ${visitFound?.isVisited === false ? styles.active : ''}`}
            onClick={(e) => handleVisit(e)}
          >
            {visitFound?.isVisited === false ? <FaMinus/> : <FaPlus />}
          </button>
        }
        {isVisit && visitFound?.isVisited === false
          && <button
            type="button"
            className={`${styles.toggle} ${visitFound?.isVisited === false ? styles['visit-active'] : ''}`}
            onClick={(e) => handleVisitStatus(e)}
          >
            <FaCheck/>
          </button>
        }
        {isVisit && visitFound?.isVisited === true
          && <button
            type="button"
            className={`${styles.visit} ${visitFound?.isVisited === true ? styles.active : ''}`}
            onClick={(e) => handleVisitStatus(e)}
            style={{
              top: `${height}px`,
            }}
          >
            {visitFound?.isVisited && <FaCheck />}
          </button>
        }
        {isVisit && visitFound?.isVisited === false
          && <button
            type="button"
            onClick={(e) => deleteVisit(e)}
            className={`${styles.delete}`}
          >
            <RiDeleteBinLine />
          </button>
        }
      </>
      }
      {!card.location.lat && !card.location?.lon && <button
          type="button"
          className={`${styles.pin}`}
          onClick={(e) => getSalesPointLocation(e)}
        >
            <LuMapPinOff />
        </button>}
    </div>
  );
};

export default Card;
