/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Select from 'react-select';
import { Control, Controller } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import { styleBase, stylePrimary } from '../style';

interface SelectProps {
  name: string,
  control: Control<any>,
  rules?: any,
  widthLabel?: string | null,
  inline?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  label?: string | null,
  className?: string
  prop?: any,
  options: any[],
}

const StyledSelect = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  isMulti = false,
  placeholder = '',
  label = null,
  className,
  options = [],
  ...props
}: SelectProps) => (
    <div className={`${styles['container-select']} ${inline ? styles.inline : ''} ${className ? styles[className] : ''}`}>
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <Controller
          control={control}
          name={name}
          rules={rules}
          render={({
            field: {
              onChange, value, ref,
            },
          }) => (
          <Select
          {...props}
          ref={ref}
          options={options}
          value={options.find((o) => o.value === value)}
          name={name}
          isMulti={isMulti}
          className={`${styles.input}`}
          placeholder={placeholder}
          styles={className === 'primary' ? stylePrimary : styleBase}
          onChange={(val) => onChange(val.value)}
      />
          )}/>
    </div>
);
export default StyledSelect;
